import { serialiseQueryParams } from "@web/utils";

import { SupplierOrder } from "src/domain";

export enum RoutesParams {
  ORDER_ID = "orderId",
  ORDER_VERSION_ID = "orderVersionId",
}

export const RoutesConfig = {
  home: "/",
  profile: `/profile`,
  companyProfile: `/company`,
  orderDetails: `/order/:${RoutesParams.ORDER_ID}`,
  confirmOrder: `/order/:${RoutesParams.ORDER_ID}/confirm`,
  confirmOrderVersion: `/order/:${RoutesParams.ORDER_ID}/:${RoutesParams.ORDER_VERSION_ID}/confirm`,
  orderConfirmed: `/order/:${RoutesParams.ORDER_ID}/confirmed`,
  orderEdit: `/order/:${RoutesParams.ORDER_ID}/edit`,
  orderEditVersion: `/order/:${RoutesParams.ORDER_ID}/:${RoutesParams.ORDER_VERSION_ID}/edit`,
  orderAttachments: `/order/:${RoutesParams.ORDER_ID}/attachments`,
};

export const apiOrderStatusToQueryStatusMap: ReadonlyMap<SupplierOrder["status"], string> = new Map(
  [
    ["INCOMING", "incoming"],
    ["CONFIRMED", "confirmed"],
    ["DELIVERED", "delivered"],
    ["REJECTED", "rejected"],
    ["CANCELLED", "cancelled"],
    ["CANCELLATION_REQUESTED", "cancellationRequested"],
  ]
);

export const queryStatusToApiOrderStatusMap: ReadonlyMap<string, SupplierOrder["status"]> = new Map(
  [
    ["incoming", "INCOMING"],
    ["confirmed", "CONFIRMED"],
    ["delivered", "DELIVERED"],
    ["rejected", "REJECTED"],
    ["cancelled", "CANCELLED"],
    ["cancellationRequested", "CANCELLATION_REQUESTED"],
  ]
);

export const getOrdersListPath = ({
  status,
  closed,
}: {
  status?: SupplierOrder["status"];
  closed?: boolean;
}): string =>
  `${RoutesConfig.home}${serialiseQueryParams({
    status: status && !closed ? apiOrderStatusToQueryStatusMap.get(status) : undefined,
    closed: closed ? closed : undefined,
  })}`;

export const getOrderDetailsPath = (orderId: string): string => `/order/${orderId}`;
