import { SupplierPortalAppService } from "src/typegens";

import { IOrderItemAdditionConfig } from "../models/OrderItemAddition";

export const orderItemAdditionConfig: Readonly<IOrderItemAdditionConfig> = {
  // Leave empty to disable consolidation or provide a list of *all* meta fields
  // that should differentiate the changes for consolidation
  consolidationComparators: [],
  apiResponseMapper: (change, apiResponse) => ({
    ...change,
    status: "success",
    meta: {
      ...change.meta,
      newOrderVersionId: apiResponse.newVersion,
    },
  }),
  apiRequestCall: async (change, signal) =>
    SupplierPortalAppService.addLineItem1(
      {
        s2SOrderId: change.meta.orderId,
        requestBody: {
          itemId: change.newValue.id,
          quantity: change.newValue.quantity,
          version: change.meta.oldOrderVersionId,
        },
      },
      { signal }
    ),
};
